<template>
    <section>
        <section
            class="bg-home-hero-two [background-size:270%_100%] md:[background-size:250%_100%] lg:[background-size:195%_100%] xl:[background-size:150%_100%] 2xl:[background-size:126%_100%] 3xl:[background-size:100%_100%] bg-center pb-14 lg:pb-[120px]">
            <div class="flex flex-col 3xl:pb-[22px]">
                <HomeHeader
                    layout="client"
                    :primary-menu-links="primaryMenuLinks"
                    :sidebar-menu-links="sidebarMenuLinks">
                    <InertiaLink
                        :href="route('vendor')"
                        class="flex items-center mt-8 justify-between border-t border-t-base-200 pt-6 hover:text-primary">
                        <span class="text-[28px] leading-10 w-10/12">{{
                            $t('Looking to provide your own talents?')
                        }}</span>
                        <ArrowUpRightIcon class="size-12 rotate-45" />
                    </InertiaLink>
                    <span class="uppercase text-base-300 text-sm">{{ $t('Learn about all the advantages ') }}</span>
                </HomeHeader>
                <section
                    class="flex flex-col gap-y-8 mt-[70px] md:mt-[78px] lg:mt-[104px] 2xl:mt-[144px] mx-6 md:mx-14 2xl:mx-[160px] 3xl:mx-[270px]">
                    <span class="w-fit font-medium text-sm inline-block text-s-gradient uppercase">{{
                        isHomePage ? $t('Grow your business and MANAGE your team IN ONE PLACE') : $t('SCALING PLATFORM')
                    }}</span>
                    <span
                        class="text-[40px] lg:text-[52px] 2xl:text-[74px] leading-[46px] lg:leading-[60px] 2xl:leading-[80px] lg:font-light tracking-tight md:w-4/5 3xl:w-3/4">
                        {{ title }}
                    </span>
                    <span class="text-xl lg:leading-8 max-w-[938px] lg:max-w-[764px] xl:max-w-[938px]">
                        {{ description }}
                    </span>
                    <div v-if="isHomePage" class="flex flex-col md:flex-row items-center gap-y-6 gap-x-8">
                        <InertiaLink
                            v-if="isHomePage"
                            :href="route('clients.register')"
                            class="md:w-fit h-14 rounded-full bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 p-px hover:bg-none cursor-pointer">
                            <div
                                class="flex justify-center items-center text-center hover:btn-sp-100 bg-primary-content rounded-full h-full group">
                                <div class="flex gap-4 items-center mx-14 md:mx-6">
                                    <span
                                        class="text-xl font-semibold bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 inline-block text-transparent bg-clip-text group-hover:text-white">
                                        {{ $t('I’m ready to scale!') }}
                                    </span>
                                    <span
                                        class="hidden md:block font-normal text-base bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 text-transparent bg-clip-text group-hover:text-white">
                                        {{ $t('Discover talents') }}
                                    </span>
                                </div>
                            </div>
                        </InertiaLink>
                        <a href="#how-it-works" class="text-base font-normal hover:text-primary">
                            {{ $t('How it works?') }}
                        </a>
                    </div>
                </section>
            </div>
        </section>
        <div
            class="relative w-full h-[328px] lg:h-80 overflow-hidden"
            :class="{
                ' h-[970px] md:h-[750px] lg:h-[710px] 2xl:h-[880px]': isHomePage,
            }">
            <video
                class="absolute h-full w-full object-cover object-center"
                autoplay
                muted
                loop
                disablePictureInPicture
                playsinline>
                <source src="/videos/team_meeting.mp4" type="video/mp4" />
            </video>
            <div class="absolute inset-0 bg-[#0E1924] opacity-75" />
            <div
                class="absolute left-1/2 -translate-x-1/2 opacity-50 -top-64 2xl:-top-[17rem] h-[447px] -rotate-90 w-96"
                :class="{'bg-dot-white': isHomePage}" />
            <div
                v-if="isHomePage"
                class="flex flex-col gap-6 lg:gap-11 absolute left-1/2 top-4 lg:top-[20%] -translate-x-1/2 text-white m-auto text-2xl lg:text-[32px] px-6 md:px-14 2xl:px-[160px] 3xl:px-[270px] w-full">
                <div class="w-full text-center mt-14 md:mt-12 lg:-mt-12 xl:mt-auto 2xl:pt-16">
                    <h1 class="text-[32px] font-normal lg:text-[38px] 2xl:text-5xl leading-10 mb-2">
                        {{ $t('Empower your team and projects') }}
                    </h1>
                    <span class="uppercase text-sm m-auto">{{
                        $t('The right professionals and clients around the world')
                    }}</span>
                    <div
                        v-if="cards.length"
                        class="flex flex-col lg:flex-row justify-between items-stretch gap-y-10 gap-x-6 py-10 mt-4 md:mt-8">
                        <div
                            v-for="(item, index) in cards"
                            :key="index"
                            class="flex-1 flex flex-col text-white border-l-[3px] border-white pl-6">
                            <h2
                                class="text-[22px] font-normal leading-8 lg:text-[32px] lg:font-light lg:leading-10 text-left mb-2 lg:mb-6">
                                {{ item.title }}
                            </h2>
                            <p class="text-base font-medium text-left flex-grow">
                                {{ item.description }}
                            </p>
                        </div>
                    </div>
                </div>
                <InertiaLink
                    :href="route('clients.register')"
                    class="z-50 flex items-center gap-4 w-fit mx-auto font-medium cursor-pointer text-lg bg-transparent text-white border border-white rounded-full px-6 py-4 text-center hover:bg-white hover:text-base-content">
                    <span class="text-lg 3xl:text-[20px] font-medium 2xl:font-semibold">
                        {{ $t('Find talents today') }}
                    </span>
                    <ArrowUpRightIcon class="size-6" />
                </InertiaLink>
            </div>
            <div
                v-else
                class="flex absolute left-1/2 top-1/4 md:top-1/3 -translate-x-1/2 text-white m-auto text-2xl lg:text-[32px] w-11/12 lg:w-2/3 xl:w-11/12 2xl:w-10/12 3xl:w-9/12 before:content-quote-icon before:w-7 before:inline-block">
                <div class="absolute my-0 mx-auto p-0 border h-4/5 top-10 left-3"></div>
                <div class="ml-6">
                    {{
                        $t(
                            'Our powerful yet easy-to-use system frees up your time to focus on what truly matters: business success.',
                        )
                    }}
                </div>
            </div>
        </div>

        <div class="bg-primary-content">
            <slot></slot>
        </div>
        <div
            class="flex flex-col items-center lg:items-start xl:items-center xl:flex-row gap-8 lg:gap-12 xl:gap-24 bg-scaling bg-cover bg-center bg-no-repeat py-14 lg:py-[72px] xl:py-[104px] px-6 md:px-14 2xl:px-[160px] 3xl:px-[270px] text-white">
            <div
                class="text-center md:text-left text-[28px] leading-9 lg:text-[34px] lg:leading-[40px] 2xl:text-[36px] 2xl:leading-[46px] xl:w-4/5 2xl:w-9/12">
                {{ $t('Are you ready for a strategic scaling?') }}
            </div>
            <div class="flex flex-col items-center lg:items-start gap-6">
                <InertiaLink
                    :href="route('clients.register')"
                    class="w-fit border rounded-full border-white py-4 px-6 text-xl font-semibold cursor-pointer bg-transparent text-white text-center hover:bg-white hover:text-base-content">
                    {{ $t('Yes! I’m ready to scale my team') }}
                </InertiaLink>
                <span class="uppercase text-sm text-center lg:text-left leading-6">{{
                    $t(
                        'Not sure what to request? No problem! Our tech team is ready to assist you to make the right strategic choice based on your goals',
                    )
                }}</span>
            </div>
        </div>
        <HomeFooter />
    </section>
</template>
<script setup lang="ts">
import HomeFooter from './Partials/Frontend/HomeFooter.vue';
import HomeHeader from './Partials/Frontend/HomeHeader.vue';
import {computed} from 'vue';
import {route} from 'ziggy-js';
import {Link as InertiaLink} from '@inertiajs/vue3';
import {ArrowUpRightIcon} from '@heroicons/vue/24/outline';
import {trans} from 'laravel-vue-i18n';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
    isHomePage: {
        type: Boolean,
        default: false,
    },
});

const primaryMenuLinks = [
    {
        href: '/#technologies',
        label: trans('Our Talents'),
    },
    {
        href: '/#advantages',
        label: trans('Advantages'),
    },
    {
        href: '/#how-it-works',
        label: trans('How it works'),
    },
    {
        href: 'vendor',
        label: trans('Talent providers'),
        button: true,
    },
    {
        href: 'path-selector',
        label: trans('Join us'),
        button: true,
        hasBackground: true,
    },
];

const sidebarMenuLinks = [
    {
        href: '/',
        label: trans('Home'),
    },
    {
        href: '/#technologies',
        label: trans('Our Talents'),
    },
    {
        href: '/#advantages',
        label: trans('Advantages'),
    },
    {
        href: '/#how-it-works',
        label: trans('How it works'),
    },
    {
        href: 'vendor',
        isRoute: true,
        label: trans('Talent providers'),
    },
    {
        href: 'https://jagaad.com',
        label: trans('About Jagaad'),
        external: true,
    },
];

const cards = computed(() => {
    return props.isHomePage
        ? [
              {
                  title: 'Need a talent for a project or idea?',
                  description:
                      'Our AI-powered system matches you with the most qualified candidates, saving you time and effort.',
              },
              {
                  title: 'Lack industry or tech expertise?',
                  description:
                      'Access a pool of highly skilled professionals who have passed our stringent evaluation.',
              },
              {
                  title: 'Looking for a tailored experience?',
                  description:
                      'Enjoy a truly tailored experience designed specifically for your unique needs and preferences.',
              },
          ]
        : [];
});
</script>
